import { getAuthorizedApi } from "api/apiInstance";
import { baseInternalUrl, baseUrlV2 } from "api/constants";
// import CommonCategoryPage from "components/category";
import { getSession } from "next-auth/react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { getCtxPayload } from "utils/loggerHelper";
import { logger } from "logging/log-util";
import { v4 as uuidv4 } from "uuid";

const CommonCategoryPage = dynamic(() => import("../../components/category"), {
  ssr: true,
});

export default function Category(props) {
  const { query } = useRouter();
  return (
    <CommonCategoryPage
      {...props}
      fromRoot={props.categoryName == "election"}
      categoryName={query.categoryName}
    />
  );
}

export async function getServerSideProps(ctx) {
  console.log("in news section");
  const { categoryName, categorySlug } = ctx.query;
  const session = await getSession(ctx);
  const lallantop = JSON.parse(ctx.req?.cookies?.lallantop || "{}");

  const ctxId = uuidv4();
  const loggerWithCtx = logger.child({
    ctxId,
    userId: lallantop?.userId,
    page: "Category",
  });
  const ctxPayload = getCtxPayload(ctx, {
    label: "Category CSR : Page Request",
  });
  loggerWithCtx.info(ctxPayload);

  const httpClient = await getAuthorizedApi(
    lallantop.encryptedToken,
    loggerWithCtx
  );
  let newsData = [];
  let liveBlogData = [];

  const { data } = await httpClient.get(
    `${baseInternalUrl}/web/postListByCategory/${
      categorySlug || categoryName
    }?limit=25&skip=1&type=text,video,liveblog`
  );
  const articlePageBanners = await httpClient.get(
    `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/banner/all?source=WEB&category=category-${categoryName}`
  );

  let catTags = await httpClient.get(`${baseUrlV2}/category/list-all`);
  catTags = catTags?.data?.result?.categories;
  const metaDetails =
    catTags?.find((category) => category?.slug === categoryName)?.metaDetails ||
    {};

  const faqData =
    catTags?.find((category) => category?.slug === categoryName)?.faqs || {};

  let electionTopicData = [];
  try {
    // if (categoryName === "election") {
    //   const topicData = await httpClient.get(
    //     `${process.env.NEXT_PUBLIC_APP_SSR_URL}/v1/topicItem/getContentsBySlugAndType?limit=6&skip=1&slug=chunav-yatra-2024&type=video`
    //   );
    //   if (topicData && topicData?.data && topicData?.data?.result?.entities) {
    //     electionTopicData = topicData?.data?.result?.entities;
    //   }
    // }
  } catch (err) {
    console.log("error on topic page ", err);
  }

  // if(categoryName === "business"){
  //   try{
  //     const liveBlog = await httpClient.get(
  //       `${process.env.NEXT_PUBLIC_LIVE_BLOG_BASE_URL}/liveBlog/list?appSlug=${process.env.NEXT_PUBLIC_APP_SLUG}&pageNumer=1&pageSize=2`
  //     );
  //     liveBlogData = liveBlog?.data?.result?.entities ? liveBlog?.data?.result?.entities : []
  //   }
  //   catch(err){
  //     console.log("LiveBlog API failed ",err);
  //   }

  // }
  var seoData = [...data?.result?.entities];

  console.log("seoData 1", seoData?.length);

  newsData = [...data.result?.entities];
  var temp = newsData;
  temp.splice(18);
  newsData = temp;
  console.log("seoData 2", seoData?.length);

  let keyValueArray = [];
  for (let i = 1; i < seoData.length + 1; i++) {
    let position = {};
    let typeKey = "@type";
    let typeValue = "ListItem";
    position[typeKey] = typeValue;
    // position.push({ [typeKey]: typeValue });
    typeKey = "position";
    typeValue = i;
    position[typeKey] = typeValue;
    typeKey = "name";
    typeValue = seoData[i - 1]?.title;
    // position.push({ [typeKey]: typeValue });
    position[typeKey] = typeValue;

    typeKey = "description";
    typeValue = seoData[i - 1]?.shortText;
    position[typeKey] = typeValue;
    typeKey = "url";
    typeValue = seoData[i - 1]?.permalink;
    position[typeKey] = typeValue;
    keyValueArray.push(position);
  }
  seoData = keyValueArray;
  try {
    if (
      categoryName === "news" ||
      categoryName === "sports" ||
      categoryName === "entertainment" ||
      categoryName === "lallankhas"
    ) {
      ctx?.res?.setHeader("Cache-Control", `max-age=120, stale-if-error=86400`);
    } else {
      ctx?.res?.setHeader("Cache-Control", `max-age=360, stale-if-error=86400`);
    }
  } catch (error) {
    console.error("cache error", error);
  }
  if (newsData?.length === 0) {
    return {
      redirect: {
        permanent: false,
        destination: "/",
        permanent: true,
      },
      props: {},
    };
  }
  return {
    props: {
      // session,
      newsData,
      liveBlogData,
      seoData,
      keyValueArray,
      electionTopicData,
      metaDetails,
      faqData: faqData,
      categoryName,
      articlePageBanners: articlePageBanners?.data?.result,
    }, // will be passed to the page component as props
  };
}
